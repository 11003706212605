/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'Golos';
	src:
		url('/assets/fonts/golos/Golos-Text_VF.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_VF.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'Golos';
	src:
		url('/assets/fonts/golos/Golos-Text_Regular.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'Golos';
	src:
		url('/assets/fonts/golos/Golos-Text_Medium.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'Golos';
	src:
		url('/assets/fonts/golos/Golos-Text_DemiBold.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_DemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'Golos';
	src:
		url('/assets/fonts/golos/Golos-Text_Bold.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

/* Generated by ParaType (http://www.paratype.com)*/
/* Font Golos Text: Copyright ? ParaType, 2018. All rights reserved.*/
@font-face {
	font-family: 'GolosTextWebBlack';
	src:
		url('/assets/fonts/golos/Golos-Text_Black.woff2') format('woff2'),
		url('/assets/fonts/golos/Golos-Text_Black.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}