@font-face {
	font-family: 'Fira Code';
	src: url(../../assets/fonts/FiraCode-Light.b450a8c2.woff2) format('woff2'), url(../../assets/fonts/FiraCode-Light.9cc0338c.woff) format('woff');
	font-weight: 300;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url(../../assets/fonts/FiraCode-Regular.87efad47.woff2) format('woff2'), url(../../assets/fonts/FiraCode-Regular.77b93677.woff) format('woff');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url(../../assets/fonts/FiraCode-Medium.4b554a75.woff2) format('woff2'), url(../../assets/fonts/FiraCode-Medium.f6641123.woff) format('woff');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url(../../assets/fonts/FiraCode-SemiBold.2c21e227.woff2) format('woff2'), url(../../assets/fonts/FiraCode-SemiBold.65eab84a.woff) format('woff');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'Fira Code';
	src: url(../../assets/fonts/FiraCode-Bold.68181605.woff2) format('woff2'), url(../../assets/fonts/FiraCode-Bold.2599098a.woff) format('woff');
	font-weight: 700;
	font-style: normal;
}
